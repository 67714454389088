<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('admin.labs.softwarePartnerList.show') }}</label>

            <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"
            />

            <label>{{ $t('admin.labs.softwarePartnerList.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                :placeholder="$t('admin.labs.sellersList.searchBy')"
              />

              <b-button
                variant="success"
                :disabled="$store.getters['auth/inactive']"
                @click="
                  $router.push({
                    name: 'software-partner-edit',
                    params: { id: 'new' },
                  })
                "
              >
                <span class="text-nowrap">{{
                  $t('admin.labs.softwarePartnerList.create')
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refSoftwarePartnersListTable"
        responsive
        show-empty
        primary-key="id"
        empty-text="No entries"
        class="position-relative"
        :items="filteredItems"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy="$store.state['software-partner'].loadingSoftwarePartners"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />

            <strong class="ml-1">{{
              $t('admin.labs.softwarePartnerList.loading')
            }}</strong>
          </div>
        </template>

        <template #cell(softwareAdmin)="data">
          <span>{{ data.item.userEmail }}</span>
        </template>

        <template #cell(qtyMeasurements)="data">
          <span>{{ getUsedMeasurements(data.item) }}</span>
        </template>

        <template #cell(updatedAt)="data">
          <span>{{
            parseDateToString(data.item.updatedAt, 'mm dd yyyy')
          }}</span>
        </template>

        <template #cell(createdAt)="data">
          <span>{{
            parseDateToString(data.item.createdAt, 'mm dd yyyy')
          }}</span>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="getStatusVariant(data.item)">
            <span>{{ getStatusText(data.item) }}</span>
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="end">
            <b-button
              v-if="
                data.item.status != 'active' &&
                data.item.status !== 'pending-authorization'
              "
              size="sm"
              variant="outline-warning"
              :id="`email-${data.item.id}`"
              :disabled="
                $store.getters['auth/inactive'] || !!data.item.deletedAt
              "
              @click="sendSignLink(data.item.userEmail)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>

            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`email-${data.item.id}`"
            >
              <b>{{ $t('tooltips.sendInvitation') }}</b>
            </b-tooltip>

            <b-button
              v-if="data.item.status === 'pending-authorization'"
              size="sm"
              variant="outline-success"
              :id="`approve-${data.item.id}`"
              @click="approveUser(data.item)"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>

            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`approve-${data.item.id}`"
            >
              <b> {{ $t('admin.users.approve') }} </b>
            </b-tooltip>

            <b-button
              size="sm"
              class="ml-1"
              variant="outline-secondary"
              :disabled="
                $store.getters['auth/inactive'] || !!data.item.deletedAt
              "
              :id="`edit-${data.item.id}`"
              @click="
                $router.push({
                  name: 'software-partner-edit',
                  params: { id: data.item.id },
                })
              "
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`edit-${data.item.id}`"
            >
              <b>{{ $t('admin.labs.softwarePartnerList.edit') }}</b>
            </b-tooltip>

            <b-button
              class="ml-1"
              size="sm"
              :id="`delete-${data.item.id}`"
              :variant="
                loading
                  ? 'outline-secondary'
                  : canRestore(data.item)
                  ? 'outline-success'
                  : 'outline-danger'
              "
              :disabled="
                loading ||
                $store.getters['auth/inactive'] ||
                (data.item.deletedAt &&
                  data.item.deletedBy !== $store.state.auth.userId)
              "
              @click="
                () => {
                  if (data.item.deletedAt) {
                    return restoreSoftwarePartner(
                      data.item.nameFantasy,
                      data.item.userEmail,
                      data.item.id,
                    )
                  }

                  selectedItem = data.item
                  deleteModal = true
                }
              "
            >
              <feather-icon v-if="canRestore(data.item)" icon="RotateCcwIcon" />
              <feather-icon v-else icon="SlashIcon" />
            </b-button>

            <b-tooltip
               v-if="canRestore(data.item)"
              triggers="hover"
              placement="bottom"
              :target="`delete-${data.item.id}`"
            >
              <b>{{
                loading
                  ? $t('admin.labs.softwarePartnerList.restoring')
                  : $t('admin.labs.softwarePartnerList.restore')
              }}</b>
            </b-tooltip>

            <b-tooltip
               v-else
              triggers="hover"
              placement="bottom"
              :target="`delete-${data.item.id}`"
            >
              <b>{{
                loading
                  ? $t('admin.labs.softwarePartnerList.delete')
                  : $t('admin.labs.softwarePartnerList.delete')
              }}</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('admin.labs.softwarePartnerList.totalOf') }}
              {{ totalItems }}
              {{ $t('admin.labs.softwarePartnerList.entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :per-page="perPage"
              :total-rows="totalItems"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <AlertDialog
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      :message="$t('admin.labs.softwarePartnerList.deleteDialog.message')"
      :title="`${$t('admin.labs.softwarePartnerList.deleteDialog.title')} ${
        selectedItem.nameFantasy
      }`"
      @action="
        deleteSoftwarePartner(
          selectedItem.nameFantasy,
          selectedItem.userEmail,
          selectedItem.id,
        )
        selectedItem = {}
        deleteModal = false
      "
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AlertDialog from '@/components/AlertDialog.vue'

import { onUnmounted } from 'vue-demi'
import store from '@/store'

import softwarePartnerStoreModule from './softwarePartnerStoreModule'
import useSoftwarePartnersList from './useSoftwarePartnersList'
import useAuthLink from '../../auth/useAuthLink'
import { parseDateToString } from '../../../utils/dates'

export default {
  name: 'SoftwarePartnersList',

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    AlertDialog,
  },

  data() {
    return {
      deleteModal: false,
      selectedItem: {},
      parseDateToString,
    }
  },

  computed: {
    filteredItems() {
      return this.softwarePartners.filter((el) =>
        el.nameFantasy.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    totalItems() {
      return this.filteredItems.length || 0
    },
  },

  methods: {
    approveUser(item) {
      this.$router.push(`/admin/users/view/${item.adminId}`)
    },
    canRestore(item) {
      return item.deletedAt && item.deletedBy === this.$store.state.auth.userId
    },
    getStatusVariant(item) {
      if (item.deletedAt) return 'danger'
      else if (item.status == 'active') return 'success'
      else if (item.status == 'pending') return 'warning'
      else if (item.status == 'pending-authorization') return 'danger'
      else return 'warning'
    },
    getStatusText(item) {
      if (item.deletedAt) return this.$t('status.inactive')
      else if (item.status == 'active') return this.$t('status.active')
      else if (item.status == 'pending') return this.$t('status.pending')
      else if (item.status == 'pending-authorization')
        return this.$t('status.requested')
      else return this.$t('status.pending')
    },
    getUsedMeasurements(item) {
      if (!item.allowedMeasurements) {
        return `${item.qtyMeasurements}/-`
      }

      return `${item.qtyMeasurements}/${item.allowedMeasurements}`
    },
  },

  setup() {
    const SP_APP_STORE_MODULE_NAME = 'software-partner'

    if (!store.hasModule(SP_APP_STORE_MODULE_NAME)) {
      store.registerModule(SP_APP_STORE_MODULE_NAME, softwarePartnerStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(SP_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SP_APP_STORE_MODULE_NAME),
    )

    const {
      currentPage,
      isSortDirDesc,
      refSoftwarePartnersListTable,
      search,
      sortBy,
      tableColumns,
      softwarePartners,
      deleteSoftwarePartner,
      restoreSoftwarePartner,
      loading,
      fetchSoftwarePartners,
      perPage,
      perPageOptions,
    } = useSoftwarePartnersList()

    fetchSoftwarePartners()

    const { sendSignLink } = useAuthLink()

    return {
      currentPage,
      isSortDirDesc,
      refSoftwarePartnersListTable,
      search,
      sortBy,
      tableColumns,
      softwarePartners,
      deleteSoftwarePartner,
      restoreSoftwarePartner,
      loading,
      perPage,
      perPageOptions,
      sendSignLink,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
